<template>
  <!-- 司机管理 -->
  <div>
    <Modal
      :title="title"
      :value="value"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="12">
            <FormItem label="运输类型" prop="transportType">
              <Select
                :disabled="defaultEdeit"
                v-model="form.transportType"
                style="width: 260px"
                placeholder="请选择运输类型"
                clearable
              >
                <Option v-for="el in orderList" :key="el.id" :value="el.id">{{
                  el.name
                }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆" prop="carId">
              <Select
                :disabled="defaultEdeit"
                v-model="form.carId"
                style="width: 260px"
                placeholder="请选择车辆"
                clearable
              >
                <Option v-for="el in carList" :key="el.id" :value="el.id">{{
                  el.plate
                }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="司机" prop="driverId">
              <Select
                :disabled="defaultEdeit"
                v-model="form.driverId"
                style="width: 260px"
                placeholder="请选择司机"
                clearable
              >
                <Option v-for="el in driverList" :key="el.id" :value="el.id">{{
                  el.name
                }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="司机电话" prop="driverPhone">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入司机电话"
                v-model="form.driverPhone"
                style="width: 260px"
                maxlength="11"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="收货人姓名" prop="receiveId">
              <Select
                :disabled="defaultEdeit"
                v-model="form.receiveId"
                style="width: 260px"
                placeholder="请选择收货人"
                clearable
              >
                <Option v-for="el in userList" :key="el.id" :value="el.id">{{
                  el.phone +
                  "(" +
                  "部门:" +
                  el.roleName +
                  ")" +
                  "(" +
                  "姓名:" +
                  el.name +
                  ")"
                }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="收货人电话" prop="receivePhone">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入收货人电话"
                v-model="form.receivePhone"
                style="width: 260px"
                maxlength="11"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="源地址" prop="originAddress">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入源地址"
                v-model="form.originAddress"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="目的地" prop="transportAddress">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入目的地"
                v-model="form.transportAddress"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="运输简介" prop="transportContent">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入运输内容简介"
                v-model="form.transportContent"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注" prop="remark">
              <Input
                clearable
                :disabled="defaultEdeit"
                type="textarea"
                placeholder="请输入需求要求"
                v-model="form.remark"
                style="width: 260px"
                :row="5"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button v-if="!defaultEdeit" type="primary" @click="submit"
          >提交</Button
        >
        <Button @click="() => $emit('input', false)">{{
          defaultEdeit ? "关闭" : "取消"
        }}</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import until from "../../common/util";
export default {
  name: "",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    DefluteValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultEdeit: Boolean,
  },

  components: {
    // carDetail,
  },
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: "添加供需",
            ca: "transport_add",
          },
          width: 200,
          // filterBox: [
          //   {
          //     conditionName: "姓名",
          //     component: "input",
          //     field: "name",
          //     defaultValue: "",
          //   },
          // ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      carList: [], //车辆列表
      driverList: [], //司机列表
      userList: [], //用户列表
      orderList: [
        { id: 1, name: "转运订单" },
        { id: 2, name: "施用订单" },
        // { id: "3", name: "自填订单" },
        // { id: "4", name: "其他" },
      ],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      carDetail: {
        show: false,
        info: {},
      },
      form: {
        userId: "",
        carId: "", //车辆ID
        driverId: "", //司机ID
        driverPhone: "", //司机电话
        receiveName: "", //收货人姓名
        receiveId: "", //接收人ID
        receivePhone: "", //接收人电话
        originAddress: "", //源地址
        transportAddress: "", //目的地地址
        transportContent: "", //运输内容简介
        remark: "", //备注
        orderNo: "", //订单号
        transportType: "", //运输类型
      },
      rules: {
        orderNo: [{ required: true, message: "请输入订单号" }],
        carId: [{ required: true, message: "请选择车辆" }],
        driverId: [{ required: true, message: "请选择司机" }],
        originAddress: [{ required: true, message: "请输入源地址" }],
        receiveId: [{ required: true, message: "请输入收货人姓名" }],
        transportAddress: [{ required: true, message: "请输入目的地" }],
        transportContent: [{ required: true, message: "请输入运输简介" }],
        driverPhone: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        receivePhone: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        transportType: [{ required: true, message: "请选择运输类型" }],
      },
    };
  },
  methods: {
    submit() {
       for (let i = 0; i < this.userList.length; i++) {
        if (this.form.receiveId == this.userList[i].id) {
          this.form.receiveName = this.userList[i].name;
          // this.form.receivePhone = this.userList[i].phone
        }
      }
      let url;
      let params = this.form;
      url = this.$api.TRANSPORT.UPDATE;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success("添加成功！");
            this.transportModel = false;
            this.$emit("input", false);
            this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id:"",
        userId: "",
        carId: "", //车辆ID
        driverId: "", //司机ID
        driverPhone: "", //司机电话
        receiveName: "", //收货人姓名
        receiveId: "", //接收人ID
        receivePhone: "", //接收人电话
        originAddress: "", //源地址
        transportAddress: "", //目的地地址
        transportContent: "", //运输内容简介
        remark: "", //备注
        orderNo: "", //订单号
        transportType: "", //运输类型
      };
      this.$refs.form.resetFields();
    },
    //获取司机列表
    getDriverList() {
      this.$post(this.$api.DRIVER_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 999,
      })
        .then((res) => {
          this.driverList = res.list;
        })
        .finally(() => {});
    },

    //获取车辆列表
    getCarList() {
      this.$post(this.$api.CAR_MANAGE.LIST)
        .then((res) => {
          this.carList = res.list;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取用户列表
    getProductList() {
      this.$post(this.$api.USER_MANAGE.ALLList)
        .then((res) => {
          this.userList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getDriverList();
    this.getCarList();
    this.getProductList();
  },
  watch: {
    value(val) {
      if (val) {
        if (this.DefluteValue) {
          //执行初始化model操作
          let formData = {};
          formData = JSON.parse(JSON.stringify(this.DefluteValue));
          this.form.id = formData.id;
          let dept=[];
          dept = JSON.parse(JSON.stringify(this.driverList));
          for (let i = 0; i < dept.length; i++) {
            if (formData.driverId == dept[i].id) {
              this.form.driverId = dept[i].id;
            }
          }
          // for (let y = 0; y < this.orderList.length; y++) {
          //   if (formData.demandType == this.orderList[y].id) {
          //     this.form.demandType = this.orderList[y].id;
          //   }
          // }
          this.form.carId = formData.carId; //车辆ID
          this.form.driverPhone = formData.driverPhone; //司机电话
          this.form.receiveName = formData.receiveName; //收货人姓名
          this.form.receiveId = formData.receiveId; //接收人ID
          this.form.receivePhone = formData.receivePhone; //接收人电话
          this.form.originAddress = formData.originAddress; //源地址
          this.form.transportAddress = formData.transportAddress; //目的地地址
          this.form.transportContent = formData.transportContent; //运输内容简介
          this.form.remark = formData.remark; //备注
          this.form.orderNo = formData.orderNo; //订单号
          this.form.transportType = formData.transportType; //运输类型
        }
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>